<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0 ">
        <div class="shape shape-myvideos  ">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container h-400px"></div>
      </section>
      <!-- 1st Hero Variation -->
    </div>
    <section class="section section-sm ">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="text-center">
              <h1>
                <strong>{{ language.my_videos_title }}</strong>
              </h1>
            </div>
            <div class="row row-grid" v-if="programs.length > 0">
              <div
                class="col-lg-12 mb-3"
                v-for="(it, i) in programs"
                v-bind:key="i"
              >
                <card class="border-0" shadow>
                  <div class="row">
                    <div class="col-sm-4">
                      <img v-lazy="it.img_header" class="mw-100 br-5" />
                    </div>
                    <div class="col-sm-8">
                      <h6 class="p-0">
                        <strong>{{ it.title }}</strong>
                      </h6>
                      <p class="text-primary small p-0">
                        <template v-for="pr in it.presenters">
                          {{ pr.name }}
                        </template>
                      </p>

                      <p
                        class="description limited-description mt-3"
                        v-html="it.description"
                      />
                      <base-button
                        tag="a"
                        :href="'/#/program/' + it.id"
                        type="primary"
                        class="float-right mt--15"
                      >
                        {{ actionMenu.begin_lesson.text }}
                      </base-button>
                    </div>
                  </div>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "MyVideos",
  components: {},
  data: function() {
    return {
      // programs: [
      //   { title: 'Very Long and Special Seminar Name 1', presenter: 'Nakasone Koichi sensei', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.' },
      //   { title: 'Very Long and Special Seminar Name 2', presenter: 'Nakasone Koichi sensei', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.' },
      //   { title: 'Very Long and Special Seminar Name 3', presenter: 'Nakasone Koichi sensei', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.' }
      // ]
    };
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
    actionMenu() {
      return this.$store.state.action_menu;
    },
    programs() {
      return this.$store.state.my_videos;
    },
  },
};
</script>
